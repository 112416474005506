<template>
    <marketing-layout :has-landing-footer="true">
        <div class="container-fluid px-0">
            <section-block class="pb-0 pt-2 py-md-8">
                <div
                    id="origination-form-container"
                    class="container"
                >
                    <div class="row align-items-md-center">
                        <div class="col-12 col-md-6 order-md-1 mb-3">
                            <img
                                class="img-fluid"
                                src="@/assets/images/pages/marketing/crypto/Hero@2x.jpg"
                                alt="Aven Crypto Card"
                            >
                        </div>
                        <div class="col-12 col-md-6 order-md-0">
                            <h1 class="h2 heading-2 text-responsive">
                                Crypto backed credit card
                            </h1>
                            <h2 class="h5 text-responsive fw-light mb-3">
                                Simple, low cost, tax-efficient way to spend without selling your crypto. 6.99% variable APR. Up to {{ toFormattedUSDKs(this.maxLineSizeCrypto) }}. No annual fees. 1.5%
                                unlimited cashback.<sup><a href="#footnote_1">1</a></sup>
                            </h2>
                            <div class="row">
                                <div class="col-12 col-md-10">
                                    <origination-invite-form
                                        id-prefix="main-origination-form"
                                        :code-required="codeRequired"
                                        :show-forgot-code-option="showForgotCodeOption"
                                        :mail-offer-link="{ name: 'invite', path: '/invite' }"
                                        ref="originationInviteFormHero"
                                        show-safe-credit-score
                                        cta-text="Get Your Card"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>

            <what-is-it-section />

            <tell-a-friend-section />

            <how-it-works-section />

            <common-questions-section />

            <more-features-soon-section />

            <section-block>
                <div class="container">
                    <div class="row gy-3">
                        <div class="col-12 col-md-6">
                            <div class="card px-3 py-5 px-md-5 py-md-7">
                                <img
                                    src="@/assets/images/pages/marketing/crypto/CreditCardIcon.svg"
                                    width="48"
                                    height="48"
                                    alt="Contact Us"
                                    class="mb-3"
                                >
                                <h3>Get Your Card</h3>
                                <h5 class="mb-3 fw-light">
                                    This will <strong>NOT</strong> affect your credit score
                                </h5>
                                <origination-invite-form
                                    id-prefix="footer-origination-form"
                                    class="mw-invite-form"
                                    :code-required="codeRequired"
                                    :show-forgot-code-option="showForgotCodeOption"
                                    :mail-offer-link="{ name: 'invite', path: '/invite' }"
                                    ref="originationInviteFormFooter"
                                    :show-safe-credit-score="false"
                                    cta-text="Get Your Card"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="card px-3 py-5 px-md-5 py-md-7 h-100">
                                <img
                                    src="@/assets/images/pages/marketing/crypto/PhoneIcon.svg"
                                    width="48"
                                    height="48"
                                    alt="Contact Us"
                                    class="mb-3"
                                >
                                <h3 class="mb-3">
                                    Reach out with<br>
                                    any questions
                                </h3>
                                <a
                                    @click="showWebWidget"
                                    class="btn btn-primary inverse w-100"
                                >
                                    {{ $t('pages.marketing.contact.message') }}
                                </a>
                                <h5
                                    class="mt-3 fw-light"
                                    v-html="$t('pages.marketing.landing.reengage.contactInfoHtml')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>
        </div>
    </marketing-layout>
</template>

<script>
    import Marketing from '@/layouts/Marketing'
    import { i18n } from '@/utils/i18n'
    import SectionBlock from '@/components/SectionBlock'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { Flows } from '@/flow/flowController'
    import OriginationInviteForm from '@/components/OriginationInviteForm'
    import CommonQuestionsSection from '@/components/landing/CommonQuestionsSection'
    import MoreFeaturesSoonSection from '@/components/landing/MoreFeaturesSoonSection'
    import { logger } from '@/utils/logger'
    import WhatIsItSection from '@/components/landing/WhatIsItSection'
    import HowItWorksSection from '@/components/landing/HowItWorksSection'
    import TellAFriendSection from '@/components/landing/TellAFriendSection'
    import zendeskMixin from '@/mixins/zendeskMixin'
    import { MAX_LINE_SIZE_CRYPTO } from '@/utils/constants'
    import format from '@/mixins/format'

    const SITE_VERSION = '1.0'
    export default {
        name: 'CryptoLanding',
        metaInfo: {
            title: 'Aven Crypto Card: A Credit Card backed by Crypto Assets',
            meta: [{ name: 'description', content: String(i18n.t('pages.marketing.landing.description')) }],
        },
        mixins: [zendeskMixin, format],
        props: {
            codeRequired: {
                type: Boolean,
                required: true,
            },
            showForgotCodeOption: {
                type: Boolean,
                required: true,
            },
        },
        data: function () {
            return {
                maxLineSizeCrypto: MAX_LINE_SIZE_CRYPTO,
            }
        },
        components: {
            'how-it-works-section': HowItWorksSection,
            'tell-a-friend-section': TellAFriendSection,
            'what-is-it-section': WhatIsItSection,
            'common-questions-section': CommonQuestionsSection,
            'more-features-soon-section': MoreFeaturesSoonSection,
            'origination-invite-form': OriginationInviteForm,
            'section-block': SectionBlock,
            'marketing-layout': Marketing,
        },
        created: function () {
            appSessionStorage.setItem(localStorageKey.currentFlow, Flows.origination)
        },
        mounted: function () {
            if (appSessionStorage.getItem(localStorageKey.jwtTokens)) {
                logger.info(`User went to landing page with jwt tokens! Clearing their stale state`)
                appSessionStorage.clear()
                window.location.reload()
                return
            }

            this.$logEvent('view_crypto_landing', { query: window.location.search, version: SITE_VERSION })
        },
        computed: {
            smsHref() {
                return `sms:${contact_info.sms}`
            },
        },
    }
</script>

<style lang="scss">
    @import '@/styles/pages/marketing/crypto';
</style>
